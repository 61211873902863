import { CurrencyPipe, DatePipe } from '@angular/common';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import {
	FileOpener,
	FileOpenerOptions,
} from '@capacitor-community/file-opener';
import {
	FreightStatus,
	FreightStatusObject,
} from '@app/shared/utils/freight-status.utils';
import { LoadingController, Platform } from '@ionic/angular/standalone';

import { CodLocation } from '@app/shared/utils/cod-location.utils';
import { CompanyInfo } from '@app/core/models/company-info.model';
import { Freight } from '@app/core/models/freight.model';
import { Injectable } from '@angular/core';
import { LocalTimePipe } from '@app/shared/pipes/local-time.pipe';
import { Share } from '@capacitor/share';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';

@Injectable()
export class PdfService {
	codLocation: any = CodLocation;
	/**
	 * Constructor.
	 * @param datePipe Date Pipe
	 * @param localTimePipe Local Time Pipe
	 * @param currencyPipe Currency Pipe
	 * @param platform Platform
	 * @param loadingCtrl Loading Controller
	 */
	constructor(
		private datePipe: DatePipe,
		private localTimePipe: LocalTimePipe,
		private currencyPipe: CurrencyPipe,
		private platform: Platform,
		private loadingCtrl: LoadingController,
	) {}

	public async createLoading() {
		return await this.loadingCtrl.create({
			message: 'Generando contrato...',
		});
	}

	async printContract(
		freight: Freight,
		carrier: CompanyInfo,
		myCompanyInfo: CompanyInfo,
	) {
		console.log('Freight: ', freight);

		const loading = await this.createLoading();
		if (this.platform.is('hybrid')) {
			loading.present();
		}
		const doc = new jsPDF();

		let currentY = 10;
		const lineHeight = 5;
		const pageHeight = doc.internal.pageSize.height; // Altura de la página

		doc.setFontSize(14);
		doc.text('Contrato del Flete', 10, currentY, {
			renderingMode: 'fillThenStroke',
		});
		doc.addImage(
			'assets/imgs/logo.png',
			'PNG',
			doc.internal.pageSize.width - 60,
			currentY - 5,
			45,
			6,
		);
		currentY += lineHeight;
		currentY += lineHeight;

		doc.setFontSize(10);
		doc.text('Order ID: ', 10, currentY, {
			renderingMode: 'fillThenStroke',
		});
		doc.text(freight.orderId, 25, currentY);
		currentY += lineHeight;

		doc.text('Estatus Actual: ', 10, currentY, {
			renderingMode: 'fillThenStroke',
		});
		const status: string = FreightStatusObject[freight.status];
		doc.text(status, 35, currentY);

		currentY += lineHeight;
		doc.text('Ultima actualizacion: ', 10, currentY, {
			renderingMode: 'fillThenStroke',
		});
		let localTime = this.localTimePipe.transform(freight.updatedAt);
		let formattedDate: any = this.datePipe.transform(
			localTime,
			'dd/MM/yyyy HH:mm:ss',
		);

		doc.text(formattedDate, 43, currentY);
		currentY += lineHeight;
		currentY += lineHeight;
		currentY += lineHeight;
		doc.setFontSize(12);
		doc.text('Información del Transportista', 10, currentY, {
			renderingMode: 'fillThenStroke',
		});
		currentY += lineHeight;
		autoTable(doc, {
			startY: currentY,
			head: [['Nombre de la Compañía', 'Contacto', 'Número de teléfono']],
			body: [
				[carrier.companyName, carrier.contact, carrier.phoneNumber],
				[
					`${carrier?.address?.direction}, ${carrier?.address?.city}, ${carrier?.address?.state}, ${carrier?.address?.zipCode}`,
					'',
					'',
				],
			],
			headStyles: {
				fillColor: '#d43a3a',
			},
		});

		currentY += lineHeight;
		doc.setFontSize(12);
		currentY += lineHeight + 20;
		doc.text('Información del Agente', 10, currentY, {
			renderingMode: 'fillThenStroke',
		});
		currentY += lineHeight;

		autoTable(doc, {
			startY: currentY,
			head: [['Nombre de la Compañía', 'Contacto', 'Número de teléfono']],
			body: [
				[
					freight.company.name,
					freight.company.contact,
					freight.company.officePhone,
				],
				[
					`${freight?.company.address?.direction}, ${freight?.company.address?.city}, ${freight?.company.address?.state}, ${freight?.company.address?.zipCode}`,
					'',
				],
			],
			headStyles: {
				fillColor: '#d43a3a',
			},
		});

		currentY += lineHeight;
		currentY += lineHeight;
		currentY += lineHeight;
		currentY += lineHeight;
		currentY += lineHeight;
		currentY += lineHeight;
		doc.setFontSize(12);

		doc.text('Información de flete', 10, currentY, {
			renderingMode: 'fillThenStroke',
		});
		currentY += lineHeight;

		let positionParagraph = currentY;
		doc.setFontSize(10);
		doc.text('Fecha de envio: ', 10, currentY);
		localTime = this.localTimePipe.transform(freight.estimatedPickupDate);
		formattedDate = this.datePipe.transform(localTime, 'dd/MM/yyyy HH:mm:ss');
		doc.text(formattedDate, 36, currentY);
		currentY += lineHeight;
		doc.text('Fecha entrega estimada: ', 10, currentY);
		localTime = this.localTimePipe.transform(freight.estimatedDeliveryDate);
		formattedDate = this.datePipe.transform(localTime, 'dd/MM/yyyy HH:mm:ss');
		doc.text(formattedDate, 50, currentY);
		currentY += lineHeight;
		doc.text('Peso (Toneladas):', 10, currentY);
		doc.text(freight.weight.toString(), 40, currentY);

		currentY = positionParagraph;

		doc.text(
			'Pago al transportista: ',
			doc.internal.pageSize.getWidth() * 0.5,
			currentY,
		);
		const carrierPaymentPrice = this.currencyPipe.transform(
			freight.carrier.carrierPaymentPrice,
		)!;
		doc.text(
			carrierPaymentPrice,
			doc.internal.pageSize.getWidth() * 0.5 + 35,
			currentY,
		);
		currentY += lineHeight;
		doc.text(
			'Método de pago al transportista: ',
			doc.internal.pageSize.getWidth() * 0.5,
			currentY,
		);

		doc.text(
			freight.carrier.codPaymentMethod,
			doc.internal.pageSize.getWidth() * 0.5 + 51,
			currentY,
		);
		currentY += lineHeight;
		doc.text(
			'Tiempo de pago:',
			doc.internal.pageSize.getWidth() * 0.5,
			currentY,
		);

		doc.text(
			this.codLocation[freight.carrier.codLocation],
			doc.internal.pageSize.getWidth() * 0.5 + 27.5,
			currentY,
		);
		currentY += lineHeight;
		currentY += lineHeight;
		// currentY += lineHeight;
		doc.text('Información de la carga', 10, currentY, {
			renderingMode: 'fillThenStroke',
		});
		currentY += lineHeight;

		positionParagraph = currentY;
		doc.setFontSize(10);
		doc.text('Equipo requerido para la carga: ', 10, currentY);
		doc.text(freight.equipmentRequirement, 60, currentY);
		currentY += lineHeight;
		doc.text('Tipo de producto: ', 10, currentY);
		doc.text(freight.productType, 38, currentY);
		currentY += lineHeight;
		doc.text('Requerimiento para el traslado:', 10, currentY);
		let splitTransferRequirement: string[] = doc.splitTextToSize(
			freight.transferRequirement,
			doc.internal.pageSize.getWidth() * 0.2,
		);
		splitTransferRequirement.forEach((text, i) => {
			doc.text(text, i == 0 ? 60 : 10, currentY);
			currentY += lineHeight;
		});

		currentY = positionParagraph;

		doc.text(
			'Apoyo en carga: ',
			doc.internal.pageSize.getWidth() * 0.5,
			currentY,
		);

		doc.text(
			freight.description,
			doc.internal.pageSize.getWidth() * 0.5 + 27,
			currentY,
		);
		currentY += lineHeight;
		doc.text('Requiere Gps:', doc.internal.pageSize.getWidth() * 0.5, currentY);

		doc.text(
			freight.gps ? 'Si' : 'No',
			doc.internal.pageSize.getWidth() * 0.5 + 23,
			currentY,
		);
		currentY += lineHeight;
		doc.text(
			'Términos adicionales:',
			doc.internal.pageSize.getWidth() * 0.5,
			currentY,
		);
		let splitTerms: string[] = doc.splitTextToSize(
			freight.additionalTerms,
			doc.internal.pageSize.getWidth() * 0.25,
		);
		splitTerms.forEach((text, i) => {
			doc.text(
				text,
				i == 0
					? doc.internal.pageSize.getWidth() * 0.5 + 35
					: doc.internal.pageSize.getWidth() * 0.5,
				currentY,
			);
			currentY += lineHeight;
		});
		currentY += lineHeight;
		currentY += lineHeight;
		doc.setFontSize(12);
		positionParagraph = currentY;
		doc.text('Información de levantamiento', 10, currentY, {
			renderingMode: 'fillThenStroke',
		});
		currentY += lineHeight;

		doc.setFontSize(10);
		if (
			freight.status == FreightStatus.notSigned ||
			freight.status == FreightStatus.archived ||
			freight.status == FreightStatus.published
		) {
			doc.text('Direccion: **OCULTO**', 10, currentY);
			currentY += lineHeight;
			doc.text(
				`${freight.origin.city}, ${freight.origin.state}, ${freight.origin.zipCode}`,
				10,
				currentY,
			);
			currentY += lineHeight;
			doc.text('Contacto: **OCULTO**', 10, currentY);
		} else {
			doc.text('Direccion: ', 10, currentY);
			let direccionSplit: string[] = doc.splitTextToSize(
				freight.origin.direction,
				doc.internal.pageSize.getWidth() * 0.35,
			);
			direccionSplit.forEach((text, i) => {
				doc.text(text, i == 0 ? 26.5 : 10, currentY);
				currentY += lineHeight;
			});

			doc.text(
				`${freight.origin.city}, ${freight.origin.state}, ${freight.origin.zipCode}`,
				10,
				currentY,
			);
			currentY += lineHeight;
			doc.text('Contacto: ', 10, currentY);
			doc.text(
				`${freight.origin.contact} Tel:${freight.origin.phoneNumber}`,
				10 + 16,
				currentY,
			);
		}

		currentY = positionParagraph;

		doc.setFontSize(12);
		doc.text(
			'Información de entrega',
			doc.internal.pageSize.getWidth() * 0.5,
			currentY,
			{
				renderingMode: 'fillThenStroke',
			},
		);
		currentY += lineHeight;

		doc.setFontSize(10);

		if (
			freight.status == FreightStatus.notSigned ||
			freight.status == FreightStatus.archived ||
			freight.status == FreightStatus.published
		) {
			doc.text(
				'Direccion: **OCULTO**',
				doc.internal.pageSize.getWidth() * 0.5,
				currentY,
			);
			currentY += lineHeight;
			doc.text(
				`${freight.destination.city}, ${freight.destination.state}, ${freight.destination.zipCode}`,
				doc.internal.pageSize.getWidth() * 0.5,
				currentY,
			);
			currentY += lineHeight;
			doc.text(
				'Contacto: **OCULTO**',
				doc.internal.pageSize.getWidth() * 0.5,
				currentY,
			);
		} else {
			doc.text('Direccion: ', doc.internal.pageSize.getWidth() * 0.5, currentY);
			let direccionSplit: string[] = doc.splitTextToSize(
				freight.destination.direction,
				doc.internal.pageSize.getWidth() * 0.35,
			);
			direccionSplit.forEach((text, i) => {
				doc.text(text, doc.internal.pageSize.getWidth() * 0.5 + 16.5, currentY);
				currentY += lineHeight;
			});

			doc.text(
				`${freight.destination.city}, ${freight.destination.state}, ${freight.destination.zipCode}`,
				doc.internal.pageSize.getWidth() * 0.5,
				currentY,
			);
			currentY += lineHeight;
			doc.text('Contacto: ', doc.internal.pageSize.getWidth() * 0.5, currentY);
			doc.text(
				`${freight.destination.contact} Tel:${freight.destination.phoneNumber}`,
				doc.internal.pageSize.getWidth() * 0.5 + 16,
				currentY,
			);
			currentY += lineHeight;
		}

		currentY += lineHeight * 2;
		doc.setFontSize(12);
		doc.text('Contrato', 10, currentY, {
			renderingMode: 'fillThenStroke',
		});

		// Texto del contrato
		let splitContractText: string[] = doc.splitTextToSize(
			freight.contract,
			doc.internal.pageSize.width - 25,
		);
		splitContractText.forEach((text) => {
			currentY += lineHeight;
			// Verifica si hay espacio suficiente para la siguiente línea
			if (currentY + lineHeight > pageHeight - 10) {
				doc.addPage();
				currentY = 10; // Reinicia la posición en la nueva página
			}
			doc.text(text, 10, currentY, {
				align: 'justify',
				maxWidth: doc.internal.pageSize.width - 25,
				baseline: 'bottom',
			});
		});

		// Texto de términos
		doc.setFontSize(10);
		currentY += lineHeight * 2;
		let splitTermsText: string[] = doc.splitTextToSize(
			`Reconozco y acepto que, una vez que el transportista haya aceptado mi solicitud, se establecerá un contrato legal. Por la presente, se otorga la autoridad para transportar esta carga a ${carrier.companyName}. Al aceptar este acuerdo, ${carrier.companyName} certifica que tiene la autoridad legal y el seguro adecuado para transportar dicha carga descrita anteriormente, únicamente en camiones propiedad de ${carrier.companyName}. Todas las facturas deben estar acompañadas de un recibo de entrega firmado y enviarse por fax a ${freight.company.name}. El precio acordado incluye todos los recargos, a menos que ${carrier.companyName} y ${freight.company.name} acuerden lo contrario. El acuerdo entre ${carrier.companyName} y ${freight.company.name}, como se describe en esta hoja de envío, es exclusivamente entre ${carrier.companyName} y ${freight.company.name}. Fletes Por México no es parte de dicho acuerdo, no tiene ninguna obligación en virtud del mismo, y renuncia expresamente a toda responsabilidad que surja o esté relacionada con dicho acuerdo.`,
			doc.internal.pageSize.width - 20,
		);
		splitTermsText.forEach((text) => {
			currentY += lineHeight;
			// Verifica si hay espacio suficiente para la siguiente línea
			if (currentY + lineHeight > pageHeight - 10) {
				doc.addPage();
				currentY = 10; // Reinicia la posición en la nueva página
			}
			doc.text(text, 10, currentY, {
				align: 'justify',
				maxWidth: doc.internal.pageSize.width - 20,
				baseline: 'bottom',
			});
		});

		const time: number = Math.floor(Date.now() / 1000);
		const filename = `contrato_flete_${freight.orderId.replace(
			/[^a-zA-Z0-9-_]/g,
			'_',
		)}_${time}.pdf`;
		try {
			if (this.platform.is('hybrid')) {
				doc.autoPrint();
				await this.saveDocument(doc, filename);
				loading.dismiss();
			} else {
				doc.autoPrint();
				doc.output('dataurlnewwindow', { filename: filename });
			}
		} catch (e) {
			loading.dismiss();
			console.log('Cannot create file:', JSON.stringify(e));
		}
	}
	private async saveDocument(doc: jsPDF, filename: string) {
		let pdfOutput = doc.output('arraybuffer');
		// Convierte ArrayBuffer a base64
		const base64Data = await this.convertArrayBufferToBase64(pdfOutput);

		// Guarda el archivo usando el base64Data
		console.log('FILE BASE64: ' + base64Data);

		const result = await Filesystem.writeFile({
			path: filename,
			data: base64Data,
			directory: Directory.Cache,
		});

		await this.openPdf(result.uri);
		console.log('File created successfully:', JSON.stringify(result));
	}

	private convertArrayBufferToBase64(buffer: ArrayBuffer): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = (reader.result as string).split(',')[1];
				resolve(base64String);
			};
			reader.onerror = reject;
			reader.readAsDataURL(new Blob([buffer]));
		});
	}
	async shareBase64File(uri: string) {
		try {
			await Share.share({
				title: 'Compartir PDF',
				text: 'Aquí tienes el archivo PDF.',
				dialogTitle: 'Compartir con',
				files: [uri],
			});
		} catch (error) {
			console.error('Error al compartir el archivo:', error);
		}
	}
	async openPdf(filePath: string) {
		try {
			const fileOpenerOptions: FileOpenerOptions = {
				filePath: filePath,
				contentType: 'application/pdf',
				openWithDefault: true,
			};
			await FileOpener.open(fileOpenerOptions);
		} catch (e) {
			console.log('Error opening file', e);
		}
	}
}

